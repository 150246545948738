.home_banner {
    /* min-height: 500px; */
    display: grid;
    grid-template-columns: 45% 50%;
    align-items: center;
    justify-content: center;
    padding: 10px;
    max-width: 1250px;
}
.right_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.left_content {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
    z-index: 50;
}
.left_content a {
    width: 150px;
    height: 35px;
    gap: 10px;
    background-color: #008C77;
    color: #fff !important;
    padding: 5px;
    border: 1px solid #008C77;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    z-index: 50;
}
.left_content a:hover {
    color: #008C77 !important;
    border: 1px solid #008C77;
    background-color: transparent;
}
.left_content a:hover .banner_btn_arrow {
    transition: all 1s ease-in-out;
    fill: #008C77;
}

.left_content button label {
    cursor: pointer;
}
.home_banner h1 {
    color: #000;
    font-size: 35px;
    text-align: left;
    width: 65%;
    margin-bottom: 20px;
    font-weight: 800;
    line-height: 3rem;
}
.circle_shadow {
    position: absolute;
    left: -170px;
    opacity: .7;
    top: -40px;
    z-index: 1;
}
.circle_shadow img {
    height: 400px;
    width: 350px;
}
@media (max-width: 800px) {
    .home_banner {
        display: flex;
        flex-direction: column-reverse;
        grid-template-columns: 100%;
        position: relative;
        width: 100%;
    }
    .right_content img {
        width: 350px;
    }
    .left_content {
        align-items: center;
        text-align: center;
        position: static;
    }
    .home_banner h1 {
        font-size: 35px;
        text-align: center;
        width: 90%;
    }
    .circle_shadow {
        left: -200px;
        opacity: .7;
        top: auto;
        bottom: -100px;
    }
  }
  @media (max-width: 360px) {
    .right_content img {
        width: 90%;
    }
  }