.news_wrapper {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}
.news_section_title h2 {
    color: #1f3957;
    text-align: center;
    font-size: 30px;
    margin-bottom: 0px;
}
.news_section_title p {
    color: gray;
    text-align: center;
    width: 50%;
    font-size: 15px;
    margin-bottom: 20px;
}
.news_section_title {
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (max-width: 969px) {
    .news_wrapper {
        width: 100%;
        padding: 10px;
    }
  }
  @media (max-width: 500px) {
    .news_section_title p {
        font-size: 12px;
    }
  }
  @media (max-width: 400px) {
    .news_section_title h2 {
        font-size: 25px;
    }
  }