.get_hired {
    padding: 50px 10px 50px 10px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: space-between;    
    align-items: center;
    width: 100%;
    background-color: #F8FFFE;
}
.upper_section {
    max-width: 1250px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.upper_section h1 {
    color: #000;
    font-size: 30px;
    text-align: left;
    width: 65%;
    margin-bottom: 20px;
    font-weight: 800;
    line-height: 3rem;
}
.upper_section p {
    font-size: 15px;
    font-weight: 700;
}
.upper_section a {
    min-width: 150px;
    height: 35px;
    gap: 10px;
    background-color: #008C77;
    color: #fff !important;
    padding: 5px;
    border: 1px solid #008C77;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    z-index: 50;
}
.upper_section a:hover {
    color: #008C77 !important;
    border: 1px solid #008C77;
    background-color: transparent;
}
.upper_section a:hover .get_hirde_banner_btn_arrow {
    transition: all 1s ease-in-out;
    stroke: #008C77;
}
.get_hired_btn_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.get_hired_btn_wrapper p {
    margin: 0px;
}
.steps_section {
    max-width: 1250px;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 40px;
    flex-wrap: wrap;
}
.number_wrapper {
    padding: 10px;
    width: 100%;
    background-color: #fff;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.272);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    z-index: 90;
    position: relative;
}

.number_1, .number_2, .number_3 {
    position: absolute;
    left: -60px;
    height: 115%;
    top: 0px;
    z-index: 50;
    /* width: 30px; */
}
.big_wrapper {
    width: 300px;
    position: relative;
}
.icon_1, .icon_2, .icon_3 {
    height: 50px;
    width: 50px;
}
.step_text_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.step_text_wrapper label, .step_text_wrapper p {
    text-align: center;
}
.step_text_wrapper label {
    font-size: 14px;
    font-weight: 900;
}
.step_text_wrapper p {
    font-size: 12px;
}
@media (max-width: 700px) {
    .upper_section {
        width: 100%;
       flex-direction: column;
    }
    .upper_section h1 {
        width: 90%;
        text-align: center;
    }
  }