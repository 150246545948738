.homePage_section {
    padding: 80px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    min-height: 500px;
}
.homePage_section h1 {
    font-size: 40px;
    font-weight: 900;
    color: #ec9e3b;
    text-align: center;
}
.subjectButton label {
    font-weight: 600;
    font-size: 15px;
}
.gap {
    gap: 10px
}
.subjectButton {
    border: none;
    background: #668b37 ;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    min-height: 150px;
    min-width: 25%;
    cursor: pointer;
    /* transition: all .2s ease-in ; */
}
.subjectButton:hover {
    background: #4b6825;
}
.subjectButton p {
    color: #fff;
}
.popular_subjects_wraper {
    width: 85%;
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;
    row-gap: 35px;
}
.popular_subjects_wraper p {
    color: #fff;
    font-size: 14px;
}

@media (max-width: 1065px) {
    .popular_subjects_wraper {
        width: 95%;
    }
  }
  @media (max-width: 980px) {
    .popular_subjects_wraper {
        grid-template-columns: 25% 25% 25%;
    }
    .homePage_section h1 {
        font-size: 30px;
    }
  }
  @media (max-width: 900px) {
    .jobItemsSection {
        width: 90% !important;
    }
  }
  @media (max-width: 650px) {
    .jobItemsSection {
        width: 100% !important;
    }
  }
  @media (max-width: 700px) {
    .popular_subjects_wraper {
        grid-template-columns: 40% 40%;
    }
  }
  @media (max-width: 500px) {
    .job_list_item {
        flex-direction: column;
        gap: 10px;
    }
    .img_content_wrapper {
        width: 100%;
    }
    .job_item_salary {
        width: 100%;
    }
  }
  @media (max-width: 400px) {
    .popular_subjects_wraper {
        grid-template-columns: 80%;
    }
  }