.about_banner {
    width: 65%;
}
.white_blue_bc {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: #F8FFFE;
    clip-path: polygon(0 0, 30% 0, 100% 100%, 0 100%, 0% 50%);
}
.job_type_inner {
    width: 100%;
    display: grid;
    grid-template-columns: 15% 15% 45%;
    justify-content: center;
    gap: 30px;
}
.job_type_inner_down {
    width: 100%;
    display: grid;
    grid-template-columns: 45% 15% 15%;
    justify-content: center;
    gap: 30px;
}
.job_types_wraper {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
@media (max-width: 1165px) {
    .job_types_wraper, .job_type_inner_down {
        width: 100%;
    }
  }
  @media (max-width: 1000px) {
    .job_type_inner, .job_type_inner_down {
        grid-template-columns: unset;
        grid-template-rows: unset;
        display: flex;
        flex-wrap: wrap;
        width: 80%;
    }
    .job_type_img {
        height: 200px !important;
    }
    .img_effect {
        height: 200px;
    }
    .job_type {
        height: 200px !important;
    }
  }
  @media (max-width: 800px) {
    .right_content {
        justify-content: center !important;
    }
  }
  @media (max-width: 400px) {
    .job_type_inner, .job_type_inner_down {
        width: 90%;
    }
    .job_type_img {
        height: 150px !important;
    }
    .img_effect {
        height: 150px;
    }
    .job_type {
        height: 150px !important;
    }
  }