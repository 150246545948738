.jobDetailsBtn1, .jobDetailsBtn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    min-width: 160px;
}
.jobDetailsBtn1 {
    background-color: #fff;
    color: #008C77;
    border: 1px solid #008C77;
}
.jobDetailsBtn1:hover {
    background-color: #008C77;
    color: #fff;
    border: 1px solid #008C77;
}
.jobDetailsBtn2 {
    background-color: #008C77;
    color: #fff;
    border: 1px solid #008C77;
}
.jobDetailsBtn2:hover {
    background-color: #fff;
    color: #008C77;
    border: 1px solid #008C77;
}
.jobDetailsBtnsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}
.jobSetails label .span {
    font-weight: bolder;
    font-size: 14px;
    color: #000;
    /* margin-bottom: 3px; */
}
.jobSetails label {
    font-size: 13px;
    color: rgb(85, 84, 84);
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: flex-end;
}
.mapEffect {
    max-width: 400px;
    max-height: 400px;
    background-color: rgba(128, 128, 128, 0.212);
}
.JobRightSection {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.JobRightSection .job_filter_box {
    width: 100%;
}
.share_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}
.share_wrapper label {
    font-size: 18px;
    color: #008C77;
    cursor: pointer;
}
.share_wrapper img {
    height: 25px;
    width: 25px;
}
.social-media {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;
    
  }
  
  .social-media-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: none;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 30px;
    transition: all .3s ease-in-out;
  }
  @media screen and (min-width:1150px){
    .social-media-btn:hover { 
        transform: scale(1.2);
        color: white !important;
        box-shadow: 0px 0px 15px 1px #fdfdfd;
  }
  }
  
  .social-media-btn:nth-child(1) {
    background-color: #25d366;
  }
  
  .social-media-btn:nth-child(2) {
    background: #1da1f2;
  }
  
  .social-media-btn:nth-child(3) {
    background: #1877f2;
  }
  
  .social-media-btn:nth-child(6) {
    background: #0a66c2;
  }
  
  .link-container {
    width: 100%;
    display: grid;
    grid-template-columns: 80% 20%;
    align-items: flex-start;
    justify-content: center;
  }
  
  .link {
    width: 100%;
    background-color: white;
    padding: 5px;
    border-radius: 30px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 100%;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }
  
  .copy-btn {
    border: none;
    background-color: black;
    border-radius: 30px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    color: white;
    text-transform: capitalize;
    position: absolute;
    font-size: 16px;
  }
  
@media (max-width: 900px) {
    .all_jibs_wrapper_900 {
        padding: 20px 10px 40px 10px;
        grid-template-columns: 100%;
    }
    .JobRightSection {
        width: 100%;
    }
    .JobDetailsSection {
        padding: 0px;
    }
  }