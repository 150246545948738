.job_list_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 5px 10px;
    border-top: 0.5px solid rgba(128, 128, 128, 0.171);
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.171);
}
.job_item_content_wrapper a {
    color: rgb(33 37 41);
}
.job_item_content_wrapper a:hover {
    color: rgba(33, 37, 41, 0.643);
}
.img_content_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}
.job_item_content_wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: left;
}
.academy_img_as_item {
    height: 90px;
    width: 90px;
    object-fit: cover;
}
.locationIcon {
    height: 15px;
    width: 15px;
}
.job_content_details {
    display: flex;
    align-items: center;
    gap: 10px;
}
.location {
    display: flex;
    align-items: center;
    gap: 5px;
}
.job_item_salary {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.academy_name {
    color: #1e87f0;
    font-weight: 700;
    font-size: 13px;
}
.job_statue {
    width: 150px;
    height: 35px;
    gap: 10px;
    background-color: #008C77;
    color: #fff !important;
    padding: 5px;
    border: 1px solid #008C77;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    z-index: 50;
}
.job_statue:hover {
    color: #008C77 !important;
    border: 1px solid #008C77;
    background-color: transparent;
}
.job_item_title {
    font-weight: 600;
}
.job_locations {
    font-size: 13px;
    opacity: 0.8;
}
.salary {
    font-size: 13px;
    font-weight: 500;
}
@media (max-width: 830px) {
    .job_content_details {
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
    }
    .job_item_content_wrapper {
        gap: 3px;
    }
  }
  @media (max-width: 730px) {
    .job_item_title {
        font-size: 14px;
    }
    .job_locations, .academy_name {
        font-size: 11px;
    }
  }
  @media (max-width: 670px) {
    .job_item_title {
        font-size: 12px;
    }
  }
  @media (max-width: 422px) {
    .academy_img_as_item {
        height: 60px;
        width: 60px;
    }
    .job_statue {
        font-size: 11px;
        min-width: unset;
        padding: 4px;
    }
    .salary {
        font-size: 11px;
    }
  }
  @media (max-width: 380px) {
    .job_list_item {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .job_item_salary {
        flex-direction: row-reverse;
        gap: 10px;
        align-items: center;
    }
  }