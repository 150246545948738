.job_post {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    /* min-height: 200px; */
    min-width: 380px;
}
.job_post_img {
    height: 200px;
    width: 380px;
    object-fit: cover;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.658);
}
.academy_profile {
    height: 70px;
    width: 70px !important;
    position: absolute;
    top: calc(200px - 25px);
    left: 20px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.692);
    z-index: 12;
    background-color: #fff;
}
.details_wrapper {
    padding: 5px;
    width: calc(100% - 102px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    right: 11px;
    position: absolute;
    top: 198px;
    z-index: 99;
}
.date {
    /* width: 380px; */
    width: 40%;
    margin-top: 3px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 10px;
    font-weight: 500;
    color: rgb(109, 109, 109);
}
.job_post_title {
    margin-top: 0px;
    font-size: 15px;
    font-weight: 700;
    color: #000;
}
.job_post_location {
    font-weight: 700;
    color: #000;
    font-size: 11px;
    min-height: 16.5px;
}
.academy_details_wrapper {
    width: 60%;
}
.job_post_data_wraper {
    width: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    background-color: #fff;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 10px;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.692);
    z-index: 10;
    min-height: 165px;
}
.job_post_numbers {
    width: 80%;
    display: grid;
    grid-template-columns: 47% 6% 47%;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}
.hr {
    height: 30px;
    width: 1px;
    background-color: #8f8f8f;
}
.job_post_number_element {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}
.job_post_number_element label {
    font-weight: 500;
    font-size: 14px;
    color: rgb(112, 111, 111);;
}
.apply_now {
    width: 60%;
    height: 35px;
    gap: 10px;
    background-color: #008C77;
    color: #fff !important;
    padding: 5px;
    border: 1px solid #008C77;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    z-index: 50;
}
.apply_now:hover {
    color: #008C77 !important;
    border: 1px solid #008C77;
    background-color: transparent;
}
.apply_now img {
    height: 15px;
    width: 15px;
    cursor: pointer;
}
.apply_now label {
    cursor: pointer;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -3px !important;
}
.swiper-pagination-bullet {
    background: #a2c563 !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet-active {
    background: #2f541f !important;
}
.MFS_list {
    padding: 20px 5px 20px 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media (max-width: 405px) {
    .job_post {
        width: 100%;
        min-width: unset;
    }
    .job_post_img {
        width: 100%;
        height: 140px;
    }
    .job_post_data_wraper {
        width: 100%;
    }
    .academy_profile {
        top: calc(140px - 25px);
        height: 50px;
        width: 50px !important;
        left: 10px;
    }
    .details_wrapper {
        width: calc(100% - 65px);
        top: 137px;
    }
    .job_post_title {
        font-size: 12px;
    }
    .job_post_location {
        font-size: 9px;
    }
    .swiper-button-prev, .swiper-button-next {
        top: 60% !important;
    }
  }