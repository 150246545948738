.blog-img {
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;
}
.blog-text {
    border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px;
}
.blog-card {
    width: 100%;
    border-radius: 35px;
}
.blog_img {
    height: 150px;
    width: 100%;
    object-fit: cover;
}
.blog-btn {
    display: flex;
    align-items: center;
    gap: 5px;
}
.blog_content {
    text-align: left;
}
.blog-card h3 {
    text-align: left;
}
.blog-text ul {
    display: flex;
}
.blog-text ul li{
    display: flex !important;
    align-items: center;
    gap: 5px;
}
.blog-card .blog-text {
    min-height: 140px;
}
.blog-card .blog-text {
    padding: 15px;
  }
.swiper-slide-active .blog-card {
    /* height: 180px;
    width: 480px; */
    transform: scale(1) !important;
}
.swiper-slide .blog-card {
    transform: scale(0.9);
  }