.jobs_list_filter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.job_filter_box {
    width: 100%;
    padding: 15px;
    border: 1px solid rgba(128, 128, 128, 0.158);
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.filter_title {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
}
.filter_form_radio_wrraper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.filter_form_radio_wrraper label {
    text-align: left;
    font-size: 13px;
    color: #6c6b6b;
}
@media (max-width: 1050px) {
    .job_filter_box {
        width: 100%;
    }
  }