.pricing_page {

}
.pricing-section {
    padding: 60px 10px 60px 10px;
}

@media (max-width: 900px) {
    .price-card .price-feature ul {
        padding: 0px;
    }
  }
  @media (max-width: 800px) {
    .pricingContainer {
        max-width: unset !important;
    }
  }