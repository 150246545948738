.reusable_banner {
    height: 350px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
}
.reusable_banner img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.reusable_banner h1 {
    z-index: 10;
    color: #fff;
    text-shadow: 1px 0px 8px #8080805c;
    font-size: 45px;
    font-weight: 600;
    text-align: center;
}