@font-face {
  font-family: "cairo";
  src: local("arab_font"),
    url('./fonts/Cairo-VariableFont_wght.ttf') format("truetype");
}
body {
  margin: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.forceArabicFont {
  font-family: 'cairo' !important;
}
.forceEnglishFont {
  font-family: 'Poppins' !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button:active {
  outline: none;
}
button:focus {
  outline: none;
}
a {
  text-decoration: none !important;
}
textarea:focus, input:focus{
  outline: none !important;
}
.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6, .uk-heading-2xlarge, .uk-heading-large, .uk-heading-medium, .uk-heading-small, .uk-heading-xlarge, h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
}
