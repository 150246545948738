.available_jobs_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    min-height: 500px;
    padding: 50px 10px 50px 10px;
    max-width: 1250px;
}
.av_jobs_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.av_jobs_header h1 {
    color: #000;
    font-size: 30px;
    text-align: left;
    width: 65%;
    margin-bottom: 0px;
    font-weight: 800;
    line-height: 3rem;
}
.swiper-button-prev, .swiper-button-next {
    background-color: #fff;
    border-radius: 50%;
    width: 30px !important;
    height: 30px !important;
    color: #323232 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.283);
}
.swiper-button-prev::after, .swiper-button-next::after {
    font-size: 18px !important;
    font-weight: bold;

}
.swiper-container-android .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}
.av_jobs_header a {
    color: #008C77;
}
.av_jobs_header a:hover {
    color: #008c77a4;
}
@media (max-width: 900px) {
    .av_swiper_container {
        width: 100%;
    }
  }
  @media (max-width: 550px) {
    .av_jobs_header {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
    .av_jobs_header h1, .av_jobs_header p {
        text-align: center;
        width: 100%;
    }
  }