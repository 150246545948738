header {
    width: 100%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.429);
    padding: 5px;
    min-height: 65px;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 101;
}
.logo_wraper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user_icon_wrapper {
    background-color: transparent !important;
    border: none !important;
    gap: 5px;
}
header .logo_wraper img {
    width: 57px;
    height: 50px;
    object-fit: cover;
    cursor: pointer;
}
header ul {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    list-style: none;
    margin: 10px 0px 0px 0px;
    padding: 0px;
    gap: 10px;
    z-index: 101;
}
header ul li button {
    border: none;
    background-color: transparent;
    font-weight: 900;
    font-size: 14px !important;
    cursor: pointer;
}
header ul li button::before {
    border-bottom: 1px solid #008C77 !important;
}
header ul li button:active {
    outline: none;
}
header ul li button:hover {
    color: rgba(255, 255, 255, 0.767);
}
.language_switch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 5px;
}
.auth_buttons_wrapper {
    z-index: 101;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 40px;
}
.auth_buttons_wrapper a {
    color: #008C77;
    padding: 5px;
    border: 1px solid #008C77;
    border-radius: 5px;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}
.auth_buttons_wrapper .reg_btn:hover {
    color: #008C77 !important;
    border: 1px solid #008C77;
    background-color: transparent;
}
.auth_buttons_wrapper .login_btn:hover {
    color: #fff !important;
    border: 1px solid #008C77;
    background-color: #008C77;
}
.reg_btn {
    background-color: #008C77;
    color: #fff !important;
}
.language_img {
    padding: 5px;
    position: absolute;
    border-bottom-left-radius: 5px;
    box-shadow: 0px 5px 8px rgb(128 128 128 / 43%);
    background-color: #fff;
    right: -51px;
    top: 65px;
    width: 50px;
    height: 35px;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 1s ease-in-out;
}
.language_img img {
    height: 20px;
    width: 20px;
}
.user_icon_wrapper {
    display: flex !important;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}
.user_icon_wrapper:active, .user_icon_wrapper:focus {
    outline: 0 !important;
    box-shadow: none !important;
}
.language_switch .dropdown-menu {
    border: none !important;
}
.user_icon_wrapper img {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid rgb(0, 140, 119);
}
.accountDropDown {
    color: rgb(0, 140, 119);
    margin-top: 3px;
}
.accountDropDown:hover {
    color: rgb(0, 140, 119);
}
.accountDropDown::before {
    border-bottom: 1px solid rgb(0, 140, 119);
}
.auth_dropdown {
    box-shadow: 0px 5px 8px rgb(128 128 128 / 43%);
}
.dropdown-toggle::after {
    border-top: .3em solid rgb(0, 140, 119) !important;
}
.dropdown-item {
    font-size: 13px;
    color: rgb(0, 140, 119) !important;
}
@media (max-width: 900px) {
    header {
        grid-template-columns: 100%;
    }
  }
  @media (max-width: 1100px) {
    .language_switch {
        right: 0px;
    }
  }